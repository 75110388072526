
/* AC_S_IMAGE-GALLERY-SLIDER - 1
--------------------------------------------------------------------------------------------*/

//items
$ac-image-gallery-items-max-width: $max-content-1200;
$ac-image-gallery-items-have-padding: false;
$ac-image-gallery-items-on-small-two-items-a-row: true;

// item
$ac-image-gallery-item-background: none;
$ac-image-gallery-item-padding: 0 $default-padding / 2;
$ac-image-gallery-item-border-radius: $default-border-radius; 
$ac-image-gallery-item-hover-background: none;
$ac-image-gallery-item-margin-bottom: 20px; // use same measure unit as column specs 
$ac-image-gallery-item-content-padding: $default-padding; 
$ac-image-gallery-item-content-align: 'cc'; // lt, lc, lb, ct, cc, cb, rt, rc, rb
$ac-image-gallery-item-border: $default-border; // defines the appearance, not the display
$ac-image-gallery-item-has-shadow: false;

// image
$ac-image-gallery-item-image-size: 'contain'; // contain or cover. The HTML element cannot have a a_cover_.. class.
$ac-image-gallery-item-image-height: 300px;
$ac-image-gallery-item-image-background: none;
$ac-image-gallery-item-image-initial-opacity: .2;
$ac-image-gallery-item-image-hover-opacity: .9;

// description
$ac-image-gallery-item-description-enabled: false;
$ac-image-gallery-item-description-color: $default-text-color-dark;
$ac-image-gallery-item-description-hover-color: $default-text-color-dark;
$ac-image-gallery-item-description-word-break: false;

@import '__image-gallery_globals';


$ac-image-gallery-slider-animate-on-center: true; // als set slider to "centerMode": true,
$ac-image-gallery-slider-gradients: true; // set a gradient on left and right, to create the illusion of fading elements
$ac-image-gallery-slider-gradient-color: $brand-light-gray; // has to be the same as the section background color

@import '__image-gallery_globals_slider';


/*--------------------------------------------------------------------------------------------*/

.ac_image-gallery_item_image_container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.ac_image-gallery_item_image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

// same height fix
.ac_image-gallery_item_content {
    min-height: 90px;
}
