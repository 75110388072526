/* FIXES
--------------------------------------------------------------------------------------------*/
//GENERAL
a {
    color: $brand-primary-darker;
}

//larger font sizes: 
body{
    font-size: 17px;
}

p{
    font-size: 1.2rem;
}


// HEROS AND HEADERS
[data-s-type="hero-alt"] .ac_hero_alt_container {
    height:200px;
    background: $brand-gray;
}

.ac_hero_slide_content_text_title {
    .column-title {
        font-size: 46px;
        font-weight: 600 !important;
        text-shadow: 1px 1px $brand-black;
    }
}

[data-s-type="hero-alt"]{
    height:200px;
}
/*
[data-s-type="hero-alt"] .ac_backdrop_image-container{
    &::after{
        content: '';
        background: url(../img/flame.png);
        width: 230px;
        height: 300px;
        display: block;
        position: absolute;
        bottom: -30%;
        right: 10%;
        z-index: 1;
        transform: scale(.6);

        @media only screen and (max-width: 870px ){
            transform: scale(.6);
            bottom: -30%;
            right: 1%;

        }

        @media only screen and (max-width: 670px ){
            transform: scale(.4);
            bottom: -55%;
            right: -10%;
        }
    }
    .ac_backdrop_image, .a_opacity-20{
        opacity: 1 !important;
    }
}*/
/*
.ac_hero_alt_slide{
    background: $brand-gray;
    &::before{
        content: '';
        background: url(../img/flame.png);
        width: 230px;
        height: 300px;
        display: block;
        position: absolute;
        bottom: -30%;
        right: 10%;
        z-index: 1;
        transform: scale(.6);

        @media only screen and (max-width: 870px ){
            transform: scale(.6);
            bottom: -30%;
            right: 1%;

        }

        @media only screen and (max-width: 670px ){
            transform: scale(.4);
            bottom: -55%;
            right: -10%;
        }
    }
}*/

.ac_hero_alt_slide_content_text{
    h1, h2,h3{
        display: inline-block;
        color: white !important;
        font-weight: 400;
        text-shadow: 1px 1px 2px black;
        font-size: 3rem;
    }
}


.ac_hero_slide_content_text {
    .ac_cta_column {
        a {
            color:$brand-white;
            border: 2px solid $brand-white;
            margin-top:10px;
        }
        a:hover {
            color:$brand-white;
            border: 2px solid $brand-primary;
        }
    }
}

[data-s-type="hero"] .ac_hero_slide_content_inner {
    justify-content: left;
}

[data-s-type="hero"] .ac_hero_container {
    height:50vh;
    //border-bottom: 2px solid $brand-light-gray;
}

.ac_item_content_title {
    color: $brand-black !important;
}

.button.v_has-icon-left .svg-container {
    margin-right:5px;
}

.button.v_has-icon-left {
    font-size:20px;
}

.ac_menu-2_logo_container {
    padding: 0px 0px 20px 0px;
}

[data-m-type="menu-2"] ul.menu.dropdown {
    font-weight: 600;
    a {
        color: $brand-black !important;
    }
}

.ac_menu-2_bar_bottom {
    border-bottom: 5px solid $brand-primary;
}

.ac_menu-2_bar_top {
    background-color: $brand-lightest-gray;
    margin-bottom: 15px;
}

.ac_menu-2_bar_bottom_content{
    width: 100%;

    #menu-main{ 
        justify-content: space-between;
    }
}

//TOPTASKS
.ac_toptasks_item_content_description {
    color:#fff;
}

.ac_toptasks_item_content_title {
    color:#fff;
}
.ac_toptasks_item_container{
    background: $brand-white;
    border-bottom: 4px solid $brand-white;
}
.ac_toptasks_item:hover {
    .ac_toptasks_item_container{
        background: $brand-white;
        border-bottom: 4px solid $brand-primary;
    }
}

.ac_toptasks_item_icon {
    background-color: #fff;

    svg {
        stroke: $brand-black;
    }
}

.ac_toptasks_item_image-container {
    background-color: $brand-darkest-gray;
}

@media only screen and (max-width:640px){
    .ac_toptasks_item:first-of-type{ margin-top: 10px;  }
    .ac_toptasks_item_container{ background: #444; border-radius: 15px !important; overflow: hidden; }
    .ac_toptasks_item_icon-container{ margin: 0;  }
    .ac_toptasks_item_image-container{ display: none; }
    .ac_toptasks_item_content{
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        .button{ margin-bottom: 0; }
        .ac_toptasks_item_content_title{ margin: 0; padding: 0;}
    }

}

//SERVICES
[data-s-id="home-services"] {
    margin-top: -30px;
//    background-image: linear-gradient(0deg, white 0%, white 20%, $brand-light-gray 20%, $brand-light-gray 80%, white 80%, white 100%);
}

[data-s-id="home-services"]:before  {
    height: 60%;
    width: 100%;
    background: url('../img/bg-color.jpg');
    background-position: bottom;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 20%;
    bottom: 20%;
    left: 0%;
    right: 0%;
    content: '';
    opacity:.3;
}

[data-s-type="services"] .ac_item_container {
    //border: 2px solid #fff;
    overflow: visible;
}

[data-s-id="home-services"] {
    padding-top:20px;
    padding-bottom: 10px;
}

[data-s-type="services"] .ac_item_content {
    background-color: $brand-white;
//    border:3px solid $brand-black;
    border-radius: 10px;

    box-shadow: 0 5px 10px rgba(0,0,0,.1);

}

[data-s-type="services"] .ac_item_content:hover {
    .ac_item_content_title {
        color: $brand-white !important;
    }
}

[data-s-type="services"] .ac_item:hover {
    background-color: none !important; 
    text-decoration: none !important;
}
[data-s-type="services"] .ac_item_content:hover {
    background-color: $brand-tertiary-darker !important;
}



[data-s-type="services"] .ac_item_content_icon {
    //border:2px solid $brand-black;
    background: linear-gradient( -90deg, $brand-primary, $brand-primary-darker );
}

[data-s-type="services"] .ac_item_content_icon svg {
    stroke: $brand-white;
    //fill: ;
}

[data-s-type="services"] .ac_item_icon svg{
    fill: $brand-primary;
}


// NEWS
[data-s-type="news"]{

   .ac_item_image-container:before {
        background: none;
    }
}

[data-s-type="news"], [data-s-type="services"], [data-s-type="brands"]{

    .ac_heading_title{
        
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        &:after{
            width: 100px;
            height: 5px;
            display: block;
            content: '';
            background: $brand-primary;
        }
    }
}

// Brands
.ac_brands_item_container {
    border:none;
}

.ac_brands_item {
    border:none;
}

[data-s-type="brands"] {
    padding:0;
}

// FOOTER
[data-f-type="footer-1"]{
    background: url('../img/bg.jpg');
    background-position: bottom;
    background-size: cover;
    background-attachment: fixed;
    .ac_footer_primary {
        background: rgba(0,0,0,.8);
        border-top: 5px solid $brand-primary-darker;
        font-size: 100%;
        .ac_socials_link {
            padding: 0;
            margin: -10px 0px 10px 20px;
        }

        .ac_socials{
            flex-flow: row wrap;
            justify-content: flex-start;
            h2{
                flex: 1 0 100%;
            }
        }
    }

    h2{
        opacity: 1;
        color: $brand-primary !important;
    }
}

@media print, screen and (min-width: 50em){
    [data-f-type="footer-1"] .ac_footer_primary_column{
        max-width: initial !important;
        flex: 1 !important;
    }

    
}

@media print, screen and (max-width: 650px ){
    [data-f-type="footer-1"]{
        padding-bottom: 0;
        .ac_footer_primary .ac_socials_link{
            margin:-10px auto 10px auto;
        }
    }
}

input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
    margin-top:-26px !important;
}

[data-s-type="hero"] .ac_hero_container {
    height: 75vh !important;
}

@media screen and (max-width: 1100px){
    [data-s-type="hero"] .ac_hero_container {
        height: 60vh !important;
    }
    
}
@media screen and (max-width: 49.9375em){
    .ac_menu-2_sticky{
        display: block;
    }

    [data-s-type="hero"] .ac_hero_container {
        height: 50vh !important;
    }
    

    #menu-mobile{
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        li{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid rgba(0,0,0,.1);
    
            a{
                text-align: center;
                display: block;
            }

            &:last-of-type{
                border-bottom: 0;
            }
        }

        .sub-menu{
            background: rgba(0,0,0,.1);
            padding: 10px 0;
            border-radius: 5px;
            li{
                border-bottom: 0;
            }
        }
    }
}