
/* AC_S_TOPTASKS - 1
--------------------------------------------------------------------------------------------*/

// items
$ac-toptasks-items-max-width: $max-content-1200;
$ac-toptasks-items-padding: 0;
$ac-toptasks-items-inner-wrapper-enabled: false;
$ac-toptasks-items-inner-wrapper-padding: $default-padding / 4;
$ac-toptasks-items-inner-wrapper-background-color: $brand-lightest-gray;
$ac-toptasks-items-have-border-dividers: false;

// item
$ac-toptasks-item-padding: $default-padding / 2;
$ac-toptasks-item-container-padding: $default-padding;
$ac-toptasks-item-has-shadow: true;
$ac-toptasks-item-background-color: $brand-lightest-gray;
$ac-toptasks-item-background-hover-color: $default-background-dark;
$ac-toptasks-item-border-radius: $default-border-radius; 

// image
$ac-toptasks-item-has-image-container: false; 
$ac-toptasks-item-has-image-container-height: 120px;
$ac-toptasks-item-has-image-container-background-color: $default-background-dark;
$ac-toptasks-item-has-image: true;
$ac-toptasks-item-has-image-initial-fade: .1;
$ac-toptasks-item-has-image-hover-fade: .5;

// icon
$ac-toptasks-item-icon-enbled: true;
$ac-toptasks-item-icon-width: 100px; // px of em
$ac-toptasks-item-icon-padding: 24px; // px or em - is deducted from icon width. Say width is 80px and padding is 15px, the actual icon is 50px;
$ac-toptasks-item-icon-margin-bottom: 20px; 
$ac-toptasks-item-icon-bg: $default-background-dark;
$ac-toptasks-item-icon-hover-bg: $default-background-light;
$ac-toptasks-item-icon-fill: $default-icon-color-light;
$ac-toptasks-item-icon-hover-fill: $default-icon-color-dark;

// title
$ac-toptasks-item-title-color: $default-text-color;
$ac-toptasks-item-title-color-hover: rgba($default-text-color-light, .9);

// description
$ac-toptasks-item-description-enabled: true;
$ac-toptasks-item-description-color: $default-text-color;
$ac-toptasks-item-description-color-hover: rgba($default-text-color-light, .9);
$ac-toptasks-item-description-margin-bottom: 1em;

// btn-container
$ac-toptasks-item-btn-container-enabled: true;
$ac-toptasks-item-btn-container-color: $default-text-color;
$ac-toptasks-item-btn-container-color-hover: rgba($default-text-color-light, .9);

@import '__toptasks_globals';

/*--------------------------------------------------------------------------------------------*/

.ac_toptasks_item {
    @include breakpoint(medium-portrait) { 
        margin-bottom: $ac-toptasks-item-has-image-container-height / 2;
    }
}
.ac_toptasks_item_container {
    display: flex;
    align-items: center;
    @include breakpoint(medium-portrait) { 
        display: block;
    }
}
.ac_toptasks_item_icon-container {
    margin-top:  -($ac-toptasks-item-icon-width * 0.66);
    @include breakpoint(small only) {
        flex: 0 0 $ac-toptasks-item-icon-width * .66;
        padding-right: $default-padding;
    }
    @include breakpoint(medium-portrait) { 
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.ac_toptasks_item_icon {
    box-shadow: $default-box-shadow;
    @include breakpoint(small only) { 
        width: $ac-toptasks-item-icon-width / 2;
        height: $ac-toptasks-item-icon-width / 2;
        padding: $ac-toptasks-item-icon-padding / 2;
        svg {
            width: $ac-toptasks-item-icon-width / 2 - $ac-toptasks-item-icon-padding;
            height: $ac-toptasks-item-icon-width / 2 - $ac-toptasks-item-icon-padding;  
        }
    } 
}
.ac_toptasks_item_content {
    @include breakpoint(medium-portrait) { 
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

