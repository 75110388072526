
/* AC_S_IMAGE-GALLERY_FULL-SCREEN - GLOBALS
--------------------------------------------------------------------------------------------*/


[data-s-type="image-gallery_full-screen"] {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $ac-image-gallery-full-screen-z-index;
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity $default-transition-speed, height 0s linear .3s, visibility 0s linear .3s;
}

body.s_is-open_image-gallery_full-screen {
    [data-s-type="image-gallery_full-screen"] {
        height: 100%;
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear, height 0s linear,  opacity $default-transition-speed .3s;
    }
}

.ac_image-gallery_full-screen_container {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}


//
//      ITEM
//

.image-gallery_full-screen_item  {

}
.image-gallery_full-screen_item_container {
    position: relative;
}


//
//      CONTENT
//

.image-gallery_full-screen_item_content {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    background: $ac-image-gallery-full-screen-content-background;
}
.image-gallery_full-screen_item_content_inner {
    margin: 0 auto;
    max-width: $ac-image-gallery-full-screen-content-max-width;
    padding: $ac-image-gallery-full-screen-content-padding;
    color: $ac-image-gallery-full-screen-content-color;
}



//
//      IMAGE
//

.image-gallery_full-screen_item_image_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.image-gallery_full-screen_item_image {

}


//
//      BTN CLOSE
//

.ac_image-gallery_full-screen_close {
    position: fixed;
    width: $ac-image-gallery-full-screen-close-icon-size;
    height: $ac-image-gallery-full-screen-close-icon-size;
    z-index: $ac-image-gallery-full-screen-z-index + 1;
    top: $ac-image-gallery-full-screen-close-icon-size / 2;
    right: $ac-image-gallery-full-screen-close-icon-size / 2;
    display: none;
    padding: $ac-image-gallery-full-screen-close-icon-padding;
    background: $ac-image-gallery-full-screen-close-icon-background-color;
    border-radius: $ac-image-gallery-full-screen-close-icon-border-radius;
    transition: all $default-transition-speed;

    @include breakpoint(medium) {
        top: $ac-image-gallery-full-screen-close-icon-size;
        right: $ac-image-gallery-full-screen-close-icon-size;
    }

    svg {
        width: $ac-image-gallery-full-screen-close-icon-size - ($ac-image-gallery-full-screen-close-icon-padding * 2);
        height: $ac-image-gallery-full-screen-close-icon-size  - ($ac-image-gallery-full-screen-close-icon-padding * 2);
        fill: $ac-image-gallery-full-screen-close-icon-color;
        transition: all $default-transition-speed;
    }

    &:hover {
        cursor: pointer;
        @include breakpoint(large) {
            background: $ac-image-gallery-full-screen-close-icon-background-hover-color;

            svg {
                fill: $ac-image-gallery-full-screen-close-icon-hover-color;
            }
        }
    }

}
body.s_is-open_image-gallery_full-screen {
    .ac_image-gallery_full-screen_close {
        display: block;
    }
}


//
//      BACKGROUND
//

.ac_image-gallery_full-screen_background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $ac-image-gallery-full-screen-z-index - 1;
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity $default-transition-speed, height 0s linear .3s, visibility 0s linear .3s;
    background: $ac-image-gallery-full-screen-background;
}
body.s_is-open_image-gallery_full-screen {
    .ac_image-gallery_full-screen_background {
        height: 100%;
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear, height 0s linear,  opacity .2s ease .2s;
    }
}

