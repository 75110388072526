/* AC_S_IMAGE_GALLERY - GLOBALS - SLIDER
--------------------------------------------------------------------------------------------*/

.ac_image-gallery_container{
    display: block;
    position: relative;
    z-index: inherit; // slick slider will handle z-index

    //slick fuoc fix
    max-height: 100px;
    overflow: hidden;
    opacity: 0;
    transition: opacity $default-transition-speed;

    // drop fouc if slick is initialized
    &.slick-initialized  {
        max-height: inherit;
        overflow: visible;
        opacity: 1;
    }

    @if $ac-image-gallery-slider-gradients == true {
        &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: 11;
            width: 10vw;
            height: 100%;
        }
        &:before {
            top: 0;
            left: 0;
            background: linear-gradient(90deg, rgba($ac-image-gallery-slider-gradient-color, 1), rgba($ac-image-gallery-slider-gradient-color, 0));
        }
        &:after {
            top: 0;
            right: 0;
            background: linear-gradient(-90deg, rgba($ac-image-gallery-slider-gradient-color, 1), rgba($ac-image-gallery-slider-gradient-color, 0));
        }
    }
}


.ac_image-gallery_item {

    @if $ac-image-gallery-slider-animate-on-center == true {
        transform: scale(.8);
        transition: all $default-transition-speed;
        

        .ac_image-gallery_item_content_inner {
            opacity: 0.4;
        }

        &.slick-center {
            transform: scale(1);
            .ac_image-gallery_item_content_inner { 
                opacity: 1;
            }
            .ac_image-gallery_item_image {
                opacity: 1;
            }
        }
    }
}

.ac_image-gallery_item_container {
    width: 100%;
}
[data-s-type="image-gallery"] {
    // align items fix: https://github.com/kenwheeler/slick/issues/179
    .slick-track{   
        display: flex;

        .slick-slide{
            display: flex;
        }
    }
    [data-s-amount-item] {
    // because all items are in one line, margin on the bottom make no sense
        margin-bottom: 0;
    }

    // if amount on small == 1, we can hide the border all together. This also prevents content grow flickers
    @include breakpoint(small only) {
        [data-s-amount-item] {
            border-left-color: transparent;
        }
    }
}
